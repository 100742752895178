import React from 'react';
import Button from '../../atoms/Button/Button';

export type TSupportSectionProps = {
  title?: React.JSX.Element | string;
  description?: React.JSX.Element | string;
  buttons?: React.ComponentProps<typeof Button>[];
};

const SupportSection: React.FC<TSupportSectionProps> = ({
  title,
  description,
  buttons,
}) => {
  return (
    <div className='flex flex-col gap-6 px-4 tablet:gap-8'>
      {title && (
        <div className='text-center text-4xl font-semibold leading-relaxed text-control-950 tablet:mx-auto tablet:max-w-[480px]'>
          {title}
        </div>
      )}
      {description && (
        <div className='text-center text-base font-normal leading-[24.8px] tracking-[0.32px] text-interface-700 tablet:mx-auto tablet:max-w-[480px]'>
          {description}
        </div>
      )}
      {buttons && buttons.length >= 1 && (
        <div className='flex w-full flex-row flex-wrap items-center justify-center gap-10 desktop:mx-auto desktop:max-w-[816px]'>
          {buttons.map((button, index) => (
            <Button key={index} {...button}>
              {button.children}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SupportSection;
