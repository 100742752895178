import { SupportSection, toRichText } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';
import toCardButtons from '../../../utils/toCardButtons';
import { useRouter } from 'next/router';
import { useAppDispatch } from '@front/master/store/store.hooks';

export type TSupportSectionConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'support-section' }
>;

const SupportSectionConnected: React.FC<TSupportSectionConnectedProps> = ({
  boxTitle,
  boxDescription,
  boxButtons,
}) => {
  const router = useRouter();
  const appDispatch = useAppDispatch();
  const currentLocale = String(router.query.locale || '');

  return (
    <SupportSection
      buttons={toCardButtons(boxButtons, currentLocale, appDispatch)}
      title={toRichText(boxTitle, { hasWrapper: false })}
      description={toRichText(boxDescription, { hasWrapper: false })}
    />
  );
};

export default SupportSectionConnected;
